import Footer from "../component/Footer";
import Manon from '../images/manon.jpg'
import Zoom from "react-reveal/Zoom";
import "./Profile.css";
function Profile7(params) {
  return (
    <>
      <Zoom />
      <div className="profile">
        <center>
          <h2>Manon de Courten</h2>
        </center>
        <div className="details">
          <div className="left">
            <img src={Manon} height={500} alt="" />
          </div>
          <div className="rit">
            <p>
              Languages; French, English, Dutch, Russian, German, Ukrainian,
              Spanish
            </p>
            <p>
              Manon de Courten is a dynamic and seasoned consultant dedicated to
              fostering social impact, equity, and inclusion within
              organizations. With extensive experience spanning over a decade,
              Manon specializes in strategic consulting, training, facilitation,
              and lecturing, particularly within civil society sectors focusing
              on human rights, conflict prevention, and rule of law. Her
              expertise lies in evaluating and co-designing programs and
              strategies to promote meaningful engagement and inclusion of
              underrepresented groups. She excels in participatory and
              learning-focused cooperation, collaborating closely with
              commissioning organizations to achieve impactful outcomes.
            </p>
            <p>
              As a freelance consultant, evaluator, and trainer since 2014,
              Manon has demonstrated her proficiency in various aspects of
              evaluation, learning, and strategic advice. Notable projects
              include co-researching and co-creating vision and action plans for
              learning trajectories on shifting power dynamics, evaluating
              multi-year programs on inclusion, and conducting baseline studies
              and mid-term reviews for initiatives countering sexual
              exploitation of children and advocating against child labor. Her
              commitment to participatory approaches is evident in her
              facilitation of workshops and training sessions, both face-to-face
              and online, aimed at empowering NGOs and youth activists.
            </p>
            <p>
              Furthermore, Manon's extensive background in project management,
              fundraising, and evaluation within international organizations
              such as OSCE and Terre des Hommes NL, coupled with her academic
              credentials including a Doctor of Philosophy and a Master of Arts,
              equip her with a comprehensive skill set to drive impactful
              change. Fluent in multiple languages and adept at intercultural
              communication, Manon brings a unique perspective and a wealth of
              knowledge to her consultancy work, making her a valuable asset to
              any monitoring and evaluation consulting firm striving to create
              positive social change.
            </p>
          </div>
        </div>
      </div>
      <Zoom />
      <Footer />
    </>
  );
}
export default Profile7;
