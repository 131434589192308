import "./Team.css";
import Ceo from "../images/luciani-removebg-preview.png";
import Teams from "../images/undraw_team_collaboration_re_ow29.svg";

import Gilbert from "../images/gilbert.jpg";
import Lowie from "../images/lowie.jpg";
import Habiba from "../images/Habiba.jpg";
import TeamCard from "../component/TeamCard";
import Moses from "../images/moses.jpg";
import Habtamu from "../images/habtamu.jpg";
import Footer from "../component/Footer";
import Zoom from "react-reveal/Zoom";
import Manon from '../images/manon.jpg'
import { Link } from "react-router-dom";

function Team(params) {
  return (
    <>
      <div className="head1">
        <center>
          <h1>THE TEAM</h1>
        </center>
      </div>
      <Zoom>
        <div className="part1">
          <p>
            Our team is more than a collection of experts; it's a force for
            transformative change. With an unwavering commitment to excellence,
            innovation, and client success, each team member plays a pivotal
            role in shaping the future of M&E. Together, we navigate
            complexities, drive impactful solutions, and redefine the standards
            of excellence in monitoring and evaluation consultancy. Meet the
            team that is dedicated to turning data into insights, challenges
            into opportunities, and aspirations into measurable impact.
          </p>
          <img src={Teams} alt="" />
        </div>
      </Zoom>

      <Zoom>
        <div className="ceo1">
          <center>
            <h4>Dr. Albino Luciani . (PhD)</h4>
            <span>CEO DEVEX GLOBAL CONSULT.</span>
          </center>
          <div className="ceo">
            <div className="card-img">
              <img src={Ceo} alt="" />
            </div>
            <div>
              <p>
                Measurement, Evaluation, Learning, Policy Performance and
                stategy expert with 18 years' experience o succesfully
                designing, implimenting ,quality assuring and evaluating public,
                private and civil sociaty programs in Africa, Asia and Middle
                east. I have 12 years' experience of strenthening MEAL systems
                through data audits and 3rd party monitoring in Gorvernace,
                Education, Education, Health, Economics recovery, Women
                Empowerment and livelihoods, Strong policy advocacy, research,
                information system and capacity building skills, Multi-country
                and Multi-sectorial experience working in 22 African, 8 Asia, 4
                Middle East, 2 Caucasus and 3 European countries
              </p>
              <button class="cta">
                <span>
                  <Link to="/DR.AlbinoLuciani(PhD)">View details</Link>
                </span>
                <svg width="15px" height="10px" viewBox="0 0 13 10">
                  <path d="M1,5 L11,5"></path>
                  <polyline points="8 1 12 5 8 9"></polyline>
                </svg>
              </button>
            </div>
          </div>
        </div>
      </Zoom>
      <Zoom>
        <div className="other_cards">
         
          <TeamCard
            photoSrc={Lowie}
            subtitle="Lowie Rosales-Kawasaki"
            title="Senior Consultant & Trainer"
            description="An accomplished international civil servant with over 25 years of experience, excelling in leadership and management across global, regional, and country contexts. Demonstrated success in guiding multi-stakeholder groups through diverse development challenges, including COVID-19 economic recovery for fairtrade producers in Africa, governance and capacity development for Fairtrade Africa, and innovative leadership in areas such as housing, urban development, women empowerment, gender equality, civil society, and grassroots engagement within the land sector."
            route="/LowieRosales-Kawasaki"
          />
          <TeamCard
            photoSrc={Gilbert}
            subtitle="Gilbert Onyango Aluoch"
            title="Senior Consultant & Trainer"
            description="Gilbert boasts over 20 years of extensive experience in designing and implementing projects, specializing in the development and humanitarian sectors. His expertise spans the creation of Monitoring and Evaluation (M&E) systems, including frameworks and plans, across diverse thematic areas such as public health, HIV/AIDS, TB, Malaria, food and agriculture security, education, climate change, resilience, natural resources management, WASH, governance, peace building, and value chain management. Gilbert is adept at utilizing various platforms, including Windows OS, SurveyCTO, Kobo, Management Information Systems (MIS), SPSS Statistics, Stata, and Microsoft Office Suite. Additionally, he possesses skills in Project Management, Knowledge Management, Organizational Learning, and Data Quality Assessment.."
            route="/Gilbert-Onyango-Aluoch"
          />
              <TeamCard
            photoSrc={Manon}
            subtitle="Manon de Courten"
            title="Senior Consultant"
            description="Manon de Courten is a dynamic consultant committed to promoting social impact, equity, and inclusion within organizations. With over a decade of experience, she specializes in strategic consulting, training, facilitation, and lecturing in civil society sectors such as human rights and conflict prevention. Manon excels in evaluating and co-designing programs to foster meaningful engagement and inclusion of underrepresented groups, collaborating closely with organizations for impactful outcomes. Her approach emphasizes participatory and learning-focused cooperation."
            route="/Manon-deCourten"
          />
           <TeamCard
            photoSrc={Habtamu}
            subtitle="Habtamu Adane Legas(Ph.D.)"
            title="Senior Consultant & Trainer"
            description="Dr. Habtamu Adane is a seasoned consultant and researcher with a profound academic background with over 10 years’ experience in the field of labor market analysis, enterprise development, impact evaluation, and health-related studies. He holds a PhD in Human Capital Formation and Labor Relations from the University of Bergamo, a master's degree in International Economics from Addis Ababa University, and a bachelor's degree in Economics from Hawassa University."
            route="/Habtamu-Adane-Legas(Ph.D.)"
          />
           <TeamCard
            photoSrc={Moses}
            subtitle="Moses Lusih"
            title="Monitoring and Evaluation Consultant "
            description="Moses Lusih is a seasoned International Programme Management, Monitoring, Evaluation, and Research Specialist with over 19 years of experience in the UN, INGOs, and government sectors. His expertise spans Health, Nutrition, Climate Change, Resilience, Disaster Risk Management, WASH, Agriculture, Food Security, Gender, and Peace Building. Moses has worked across Africa and Asia-Pacific, collaborating with major donors like USAID, UNICEF, WFP, ECHO, EU, and the World Bank. Skilled in Results-based management, Logframe Approach, and Theory of Change, he currently guides UNICEF in priority evaluation topics and leads teams. In his previous role with the Government of Kenya, he served as a Monitoring and Evaluation Expert, overseeing the development of M&E systems and manuals for the Financing Locally-led Climate Action Programme."
            route="/Moses-Lusih"
          />
          <TeamCard
            photoSrc={Habiba}
            subtitle="Ms. Habiba Bahati Abdalla"
            title="project Assistant"
            description="Our commitment to excellence extends to every member of our team, including the invaluable contributions of our Project Assistant. Habiba is both dynamic and indispensable. With a focus on coordination, data management, and effective communication, our project assistant contributes significantly to the successful execution of projects. Her commitment to excellence aligns seamlessly with our overarching objective."
            route="/Habiba-Bahati-Abdalla"
          />
          
        </div>
      </Zoom>
      <Footer />
    </>
  );
}
export default Team;
