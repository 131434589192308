import React, { lazy, Suspense } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Nav from "./component/Nav";
import Notfound from "./pages/Notfound";
import Team from "./pages/Team";
import CeoProfile from "./pages/CeoProfile";
import Profile1 from "./pages/Profile1";

import Profile3 from "./pages/Profile3";
import Profile4 from "./pages/Profile4";
import Profile5 from "./pages/Profile5";
import Profile6 from "./pages/Profile6";
import "./App.css"
import Profile7 from "./pages/Profile7";

// Lazily load components
const Home = lazy(() => import("./pages/Home"));
const About = lazy(() => import("./pages/About"));
const Services = lazy(() => import("./pages/Services"));
const Portfolio = lazy(() => import("./pages/Portfolio"));
const ContactForm = lazy(() => import("./pages/Contact"));
const Agents = lazy(() => import("./pages/Agents"));
const Consultants = lazy(() => import("./pages/Consultants"));

function App() {
  return (
    <>
      <BrowserRouter>
        <Nav/>
        <div>
        <Suspense fallback={<div className="spinner-container"><div className="spinner">....</div></div>}>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/About-us" element={<About />} />
              <Route path="/Services" element={<Services />} />
              <Route path="/Portfolio" element={<Portfolio />} />
              <Route path="/consortiams" element={<ContactForm />} />
              <Route path="/Contracting Agencies" element={<Agents />} />
              <Route path="/Consultants" element={<Consultants />} />
              <Route path="/Team" element={<Team></Team>} />
              <Route path="/DR.AlbinoLuciani(PhD)" element={<CeoProfile/>} />
              <Route path="/LowieRosales-Kawasaki" element={<Profile1/>} />
             
              <Route path="/Gilbert-Onyango-Aluoch" element={<Profile3/>} />
              <Route path="/Habiba-Bahati-Abdalla" element={<Profile4/>} />
              <Route path="/Habtamu-Adane-Legas(Ph.D.)" element={<Profile5/>} />
              <Route path="/Moses-Lusih" element={<Profile6/>} />
              <Route path="/Manon-deCourten" element={<Profile7/>} />
              <Route path="*" element={<Notfound />} />
            </Routes>
          </Suspense>
        </div>
      </BrowserRouter>
    </>
  );
}

export default App;
