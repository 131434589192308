// TeamCard.jsx
import React from "react";
import "./TeamCard.css";
import { Link } from "react-router-dom";

const TeamCard = ({ photoSrc, subtitle, description, route,title }) => {
  return (
    <div className="team-card">
      <img src={photoSrc} alt={subtitle} className="team-card-photo" />
      <h3 className="team-card-subtitle">{subtitle}</h3>
      <h4>{title}</h4>
      <p className="team-card-description">{description}</p>

      <button className="team-card-button">
        {" "}
        <Link to={route} >
          View Details
        </Link>
      </button>
    </div>
  );
};

export default TeamCard;
