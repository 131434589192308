import Footer from "../component/Footer";
import Habiba from "../images/Habiba.jpg";
import Zoom from 'react-reveal/Zoom';
import './Profile.css'
function Profile4(params) {
  return (
    <>
    <Zoom>
      <div className="profile">
        <center>
          <h2>Ms. Habiba Bahati Abdalla</h2>
        </center>
        <div className="details">
          <div className="left">
        <img src={Habiba} alt="" /> 
        </div>
        <div className="rit">

        <p>
          Our commitment to excellence extends to every member of our team,
          including the invaluable contributions of our Project Assistant.
          Habiba is both dynamic and indispensable. With a focus on
          coordination, data management, and effective communication, our
          project assistant contributes significantly to the successful
          execution of projects. Her commitment to excellence aligns seamlessly
          with our overarching objective: turning data into insights and making
          a lasting impact on the organizations and communities we serve.
        </p>
        <p>
          Ms. Habiba play a central role in ensuring the seamless execution of
          projects, facilitating the delivery of precise, data-driven insights
          to our clients. Her essential responsibilities and expertise that
          define the role of a Project Assistant in our mission to elevate
          Monitoring and Evaluation (M&E) practices.
        </p>
       <h4>Core Responsibilities:</h4>
       <ol>
        <li><b>Coordination and Planning:</b>
            <ul>
                <li>
                She is the linchpin of coordination, ensuring that all elements of a project align with established timelines and objectives.
                </li>
                <li>
                Together with the team, she collaborates with different teams, clients, and stakeholders to guarantee a smooth workflow.
                </li>

            </ul>
        </li>
        <li><b>Data Management:</b>
            <ul>
                <li>
                A critical responsibility is the efficient handling and organization of project-related data.

                </li>
                <li>
                She maintains meticulous records, ensuring data integrity and accessibility for analysis.
                </li>
            </ul>
        </li>
        <li><b>Documentation and Reporting:</b>
            <ul>
                <li>
                From progress reports to documentation of key project milestones, our project assistant plays a pivotal role in producing accurate and comprehensive reports.

                </li>
                <li>
                She works closely with evaluators and analysts to translate data into actionable insights.
                </li>
            </ul>
        </li>
        <li><b>Logistical Support:</b>
            <ul>
                <li>
                Ensuring that field activities, surveys, and assessments run smoothly is a core responsibility for our project assistant
                </li>
                <li>
                She provides logistical support, ensuring that all necessary resources are in place for successful data collection.
                </li>

            </ul>
        </li>
        <li><b>Client Communication:</b>
            <ul>
                <li>
                Our Project Assistant serves as a bridge between the consultancy firm and clients.
                </li>
                <li>
                She helps facilitate regular communication, providing updates, addressing inquiries, and ensuring client satisfaction throughout the project lifecycle.
                </li>
         
            </ul>
        </li>
       </ol>
       </div>
       </div>
      </div>
      </Zoom>
      <Footer />
    </>
  );
}
export default Profile4;
