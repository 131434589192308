import './Notfound.css'

function Notfound(params) {
    return(
        <>
        <div className="not">
        <h1>Page does not exist </h1>
        </div>
        </>
    )
    
}
export default Notfound