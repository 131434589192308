import Lowie from "../images/lowie.jpg";
import Footer from "../component/Footer";
import "./Profile.css";
import Zoom from 'react-reveal/Zoom';
function Profile1(params) {
  return (
    <>
    <Zoom>
      <div className="profile">
        <center>
          <h2>Lowie Rosales-Kawasaki </h2>
        </center>
        <div className="details">
          <div className="left-side">
        <img src={Lowie} height={500} alt="" />
        </div>
        <div className="rit-side">
        <p>
          Languages: English – native language; Filipino – native language;
          Spanish – basic; Japanese - basic
        </p>
        <p>
          An international civil servant who has more than 25 years work
          experience at global, regional and country levels, and who
          successfully led, managed and coached multi-stakeholders groups in a
          range of development issues such as emergency, relief and economic
          recovery from COVID-19 of fairtrade producers in Africa; governance,
          capacity development and advocacy for Fairtrade Africa; supporting
          innovative leadership and local governance, housing and urban
          development, women empowerment and gender equality, civil society and
          grassroots engagement in the land sector.{" "}
        </p>
        <p>
          Passionate, with a strong vision for justice, gender equality and
          human rights, backed up with diverse and wide-ranging expertise in
          setting up teams and managing change processes within groups and
          institutions. A trained facilitator, with exceptional communication
          and negotiation skills, providing confidence and empathy to work
          colleagues and partners.
        </p>
        <p>
          Her experiences include; being a team leader of the RECOVER Africa
          Project, commissioned by Fairtrade Africa and funded by the German
          Development Cooperation Agency (BMZ-GIZ), I led a diverse team of
          experts and staff members towards a successful delivery of relief and
          economic recovery interventions intended to support 250,000 fairtrade
          producers and their families in 15 countries across Africa to protect
          themselves against and recover from the negative effects of the
          coronavirus pandemic; Working with United Nations Human Settlements
          Programme (UN-Habitat) stationed in the Global Land Tool Network
          Secretariat (GLTN), headquartered in Nairobi, Kenya, she led and
          managed the Secretariat work on women empowerment and gender equality
          on land, led the team in developing a change management framework for
          country level work applied in three countries in Africa, Asia and
          Latin America; As a consultant for UNDP and USAID funded projects in
          the Philippines, she developed a track record with the donors and
          national government partners for successfully implementing high-level
          outputs as project coordinator, with a strong constituency-base and
          ownership of various stakeholders; As part of the social reform and
          anti-poverty Secretariat established by the Office of the President in
          the Philippines, she contributed to the policies, programmes and
          processes that enabled civil society and basic sectors to engage more
          meaningfully in national governance
        </p>
        </div>
        </div>
      </div>
      </Zoom>
      <Footer />
    </>
  );
}
export default Profile1;
