import Footer from "../component/Footer";
import Ceo from "../images/luciani-removebg-preview.png";
import "./Profile.css";

function CeoProfile(params) {
  return (
    <>
      <div className="ceoprofile">
        <center>
          <h1>DR. Albino Luciani M. (PhD)</h1>
          <h2>
            <span>CEO DEVEX GLOBAL CONSULT.</span>
          </h2>
        </center>
        <div className="details">
          <div className="ceo-image">
            <center>
              <img src={Ceo} height={500} alt="" />
            </center>
          </div>
          <div className="profile-desc">
            <p>
              With an extensive 18-year career, he has emerged as a seasoned
              expert specializing in the design, implementation, and evaluation
              of diverse programs across Africa, Asia, and the Middle East. His
              professional journey has traversed the public, private, and civil
              society sectors, where he has made significant contributions to
              areas such as Governance, Education, Health, Economic Recovery,
              Women Empowerment, and Livelihoods.
            </p>
            <p>
              With 12 years of experience dedicated to the enhancement of
              Measurement, Evaluation, Learning, and Policy Performance systems,
              he has played a pivotal role in conducting meticulous data audits
              and implementing third-party monitoring mechanisms. This expertise
              ensures that the programs under his purview adhere to the highest
              standards and achieve a positive impact.
            </p>
            <p>
              His skill set extends beyond program implementation, encompassing
              policy advocacy, research, information systems management, and
              capacity building. These skills have been applied across a diverse
              landscape, spanning 22 African nations, 8 Asian countries, 4
              Middle Eastern regions, 2 Caucasus countries, and 3 European
              nations.
            </p>
            <p>
              In Policy Advocacy and Influencing, he has supported relevant
              Ministries of Health, Education, Gender and Social Services across
              Africa, Asia and Middle East through data analysis, documentation
              of evaluation/research findings and/or being a team lead or member
              of select committees/sub-committees. Separately, he has supported
              organizational strategy development and strategic planning
              processes through strategic prepositioning, institutional and
              government policy alignment as well as organizational theories of
              change.
            </p>
            <p>
              In essence, his illustrious career reflects a steadfast commitment
              to excellence in program design and evaluation. Through his
              expertise in MEAL, policy advocacy, and capacity building, he has
              left an indelible mark on various regions, contributing
              significantly to positive change and sustainable development. His
              multifaceted approach demonstrates a nuanced understanding of the
              diverse contexts in which he has operated, allowing him to tailor
              strategies that address unique challenges and opportunities in
              each setting.
            </p>
            <h3>His Skills in Data Analysis are as below;</h3>
            <ul>
              <li>
                Data Management: DHIS, KoBo, Magpi, ODK, GoFormz and
                DeviceMagic.{" "}
              </li>
              <li>
                Quantitative Data Analysis: STATA, SPSS, Epi-Info, FoxPro,
                Statgraphics, R, SAS and Ms Excel{" "}
              </li>
              <li>Spatial Data Analysis: ArcGIS, Quantum GIS and GeoDa </li>
              <li>Qualitative Data Analysis: N-VIVO, Atlas-Ti and Quantum. </li>
            </ul>
            <p>
              Dr. Albino Luciani has led or team member in over 480
              surveys/assessments/evaluations/impact evaluations/strategic
              planning/policy advocacy assignments. He has also managed 130
              consultancies. As the technical advisor at UNAIDS TSF-EA, he led
              and participated in 39 operation research studies and 9 clinical
              research trials.
            </p>
          </div>
        </div>
      </div>
      <Footer></Footer>
    </>
  );
}
export default CeoProfile;
