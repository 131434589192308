
import { CiLocationOn } from "react-icons/ci";
import { FaGlobe } from "react-icons/fa";
import { FaPhoneAlt } from "react-icons/fa";
import { IoMdMail } from "react-icons/io";
import './Footer.css'
function Footer(params) {
    return(
        <>
        <div className="footer">
          <div className='foter'>
            <center>
              <h1>Devex Global</h1>
     
            <p><CiLocationOn /> The mint hub Westlands, Karuna Road</p>
            <p><FaGlobe /> www.devexglobal.com</p>
            <p><FaPhoneAlt /> +254 752 889 900</p>
            <p><IoMdMail /> info@devexglobal.com</p>
           
            </center>
         </div> 
         <div className="footer-bottom">
                <p>&copy; DevexGlobal 2024</p>
                <p>Developed by <a href="https://creativemorgan.netlify.app/">Creativemorgan</a></p>
            </div>
        </div>
        
        </>
    )
    
}
export default Footer;