import Footer from "../component/Footer";
import Moses from "../images/moses.jpg";
import Zoom from "react-reveal/Zoom";
import "./Profile.css";
function Profile6(params) {
  return (
    <>
      <Zoom>
        <div className="profile">
          <center>
            <h2>Moses Lusih</h2>
          </center>
          <div className="details">
            <div className="left">
              <img src={Moses} alt="" />
            </div>
            <div className="rit">
              <p>
                Moses Lusih is an accomplished International Programme
                Management, Monitoring, Evaluation, and Research Specialist with
                over 19 years of experience across the UN, INGOs, and
                Governments. His expertise spans diverse thematic areas,
                including Health and Nutrition, Climate Change, Resilience,
                Disaster Risk Management, WASH, Agriculture, Food Security,
                Gender, and Peace Building. His geographical experience covers
                Africa (Somalia, Kenya, South Sudan, Swaziland, Sudan, Lesotho,
                Libya, Sierra Leone, and Rwanda) and Asia-Pacific regions
                (Afghanistan, Syria, and Myanmar). Moses is well-versed in
                working with major donors such as USAID, UNICEF, WFP, ECHO, EU,
                and World Bank.
              </p>
              <p>
                Moses is highly skilled in Results-based management, Logframe
                Approach, Theory of Change, and the design and evaluation of
                programs. His work with UNICEF as an Evaluation and Research
                Consultant involves guiding the Country Office and partners in
                identifying priority evaluation topics, leading evaluation
                teams, and ensuring the dissemination of knowledge products. In
                his previous role with the Government of Kenya, he served as a
                Monitoring and Evaluation Expert for the Financing Locally-led
                Climate Action Programme. His responsibilities included
                developing the M&E system, designing the M&E Manual, and
                overseeing reporting and evaluation processes.
              </p>
              <p>
                With a Master's in Project Planning and Management from the
                University of Nairobi, Moses is a certified CPA (Kenya) and
                holds a PMDPro Level 1 certification. His extensive consulting
                experience includes leading evaluations for UNFPA, Oxford Policy
                Management, Concern Worldwide, and others. Moses excels in
                strategic planning, organizational skills, and facilitating
                partnerships. His proven ability to produce high-quality
                analytical reports and manage complex scenarios showcases his
                exceptional problem-solving skills and strong communication
                abilities.
              </p>
              <p>
                In his recent role as an Evaluation and Research Consultant with
                UNICEF in Sierra Leone, Moses has been instrumental in guiding
                the evaluation process, ensuring compliance with UNICEF
                policies, and establishing strategic partnerships. His work with
                the Government of Kenya showcases his ability to develop and
                implement M&E systems for large-scale climate action programs.
                As an independent consultant, Moses has led various evaluations,
                including UNFPA Country Programme evaluations, Third-Party
                Monitoring for Oxford Policy Management, and baseline surveys
                for HIVOS East Africa.
              </p>
              <p>
                Moses Lusih's diverse experience, combined with his skills in
                program design, management, and M&E, positions him as a seasoned
                consultant capable of driving impactful evaluations and
                contributing to the success of development programs. His
                extensive track record with major donors and UN agencies
                underscores his credibility and effectiveness in the field of
                Monitoring and Evaluation.
              </p>
            </div>
          </div>
        </div>
      </Zoom>
      <Footer />
    </>
  );
}
export default Profile6;
