// Nav.js

import React, { useState } from "react";

import "./Nav.css"; // Import the CSS file
import { Link } from "react-router-dom";
import Logo from "../images/DVG-.png";

function Nav(params) {
  const [isMenuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!isMenuOpen);
  };

  return (
    <>
      <nav>
        <div className={`nav-container ${isMenuOpen ? "menu-open" : ""}`}>
          <div className="logo">
            <Link to="/">
              {" "}
              <img className="logo" src={Logo} height={100} alt="" />
            </Link>
          </div>

          <div
            className={`menu-icon ${isMenuOpen ? "open" : ""}`}
            onClick={toggleMenu}
          >
            <div className="bar"></div>
            <div className="bar"></div>
            <div className="bar"></div>
          </div>

          <ul className={`nav-menu ${isMenuOpen ? "show" : ""}`}>
            <li>
              <Link to="/About-us">About us</Link>
            </li>
            <li>
              <Link to="/Services">What we do</Link>
            </li>
            <li>
              <Link to="/Portfolio">Portfolio</Link>
            </li>
            
            <li>
              <Link to="/Team">Team</Link>
            </li>
            <li className="dropdown">
              <span className="dropbtn">Work with us</span>
              <div className="dropdown-content">
                <Link to="/Contracting Agencies">Contracting Agencies</Link>
                <Link to="/Consortiams">Consortiams</Link>
                <Link to="/Consultants">Consultants </Link>
              </div>
            </li>
          </ul>
        </div>
      </nav>
    </>
  );
}

export default Nav;
