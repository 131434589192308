import Footer from "../component/Footer";
import Habtamu from "../images/habtamu.jpg";
import Zoom from "react-reveal/Zoom";
import "./Profile.css";
function Profile5(params) {
  return (
    <>
      <Zoom>
        <div className="profile">
          <center>
            <h2>Habtamu Adane Legas(Ph.D.)</h2>
          </center>
          <div className="details">
            <div className="left">
              <img src={Habtamu} height={500} alt="" />
            </div>
            <div className="rit">
              <p>
                Dr. Habtamu Adane is a seasoned consultant and researcher with a
                profound academic background with over 10 years’ experience in
                the field of labor market analysis, enterprise development,
                impact evaluation, and health-related studies. He holds a PhD in
                Human Capital Formation and Labor Relations from the University
                of Bergamo, a master's degree in International Economics from
                Addis Ababa University, and a bachelor's degree in Economics
                from Hawassa University.
              </p>
              <p>
                Dr Habtamu is currently an Assistant Professor of Economics at
                Addis Ababa University, Department of Economics
              </p>
              <h4>Publications: </h4>
              <p>
                Dr. Adane has contributed significantly to
                academia, publishing research articles in reputable journals.
                Some notable publications include:
              </p>
              <ol>
                <li>
                "The state as an enabler of private sector development in Ethiopia" (IGC Publications, Oct 2023).
                </li>
                <li>
                "Taxing the harms in Ethiopia: Simulating the Budgetary and Distributional Impacts of Higher VAT on Alcohol Consumption" (UNWIDER South MOD Policy note, Dec 2023).
                </li>
                <li>
                "Impact Evaluation of Literacy Boost Project Model on Reading Skills of Early Grade Students in Ethiopia" (International Journal of Education Management, Vol. 34 No. 2, 2020).
                </li>
              </ol>
              <p>Selected Training and Conference Participations: Dr. Adane has continually expanded his expertise through various training programs and conferences, including:</p>
              <ol>
                <li>
                IZA/DFID Short Course on Program Evaluation.
                </li>
                <li>
                Course on Health Financing and Evaluation of Health Policies organized by International Institute of Social Studies (ISS).
                </li>
                <li>
                Applied Econometrics with Software Applications organized by Ethiopian Economics Association.
                </li>
              </ol>
              <p><b>Consultancy Experiences: </b>Dr. Adane has successfully led and participated in numerous consultancy projects, demonstrating his proficiency in research and project management. Some key experiences include:</p>
              <ol>
                <li>
                Team Leader for Christian Aid Ethiopia's project on Strengthening Social Protection Mechanisms.
                </li>
                <li>
                Lead Researcher for the Partnership for Economic Policy (PEP) project on Youth Employment Policy Review.
                </li>
                <li>
                Team Leader for Amref Health Africa's Power to Youth Mid Term Review
                </li>
                <li>
                Researcher for the International Growth Centre (UK) project on Assessing the Skill Gaps and Internship/Apprenticeship Experience in Ethiopia.
                </li>
              </ol>
              <p>Dr. Habtamu Adane's rich academic background, extensive consultancy experience, and commitment to excellence make him a valuable asset for any project requiring expertise in labor market analysis, enterprise development, and impact evaluation.</p>
            </div>
          </div>
        </div>
      </Zoom>
      <Footer />
    </>
  );
}
export default Profile5;
