import Gilbert from "../images/gilbert.jpg";
import Footer from "../component/Footer";
import Zoom from 'react-reveal/Zoom';
import './Profile.css'
function Profile3(params) {
  return (
    <>
    <Zoom>
      <div className="profile">
        <center>
          <h2>Gilbert Onyango Aluoch</h2>
        </center>
        <div className="details">
          <div className="left">
        <img src={Gilbert} height={500} alt="" /> 
        </div>
        <div className="rit">

        <p>
          Gilbert has over 20 years’ experience in design and implementation of
          Project design, M&E systems including M&E frameworks and plans across
          different thematic areas within development and humanitarian nexus.
          Sectoral experience includes public health with bias towards HIV/AIDS,
          TB, Malaria, food and agriculture security, education, climate change
          & Resilience, natural resources management, WASH, governance and peace
          building, and value chain management. Other skills which he has
          include technical proficiency in the following platforms: Windows OS,
          SurveyCTO, Kobo, Management Information Systems (MIS), SPSS
          Statistics, Stata, Microsoft Office Suite (Word, Excel, PowerPoint,
          Access, Outlook), GIS. Other competencies are in Project Management,
          Knowledge Management, Organizational Learning and Data Quality
          Assessment.
        </p>
        <p>
          Gilbert possesses a vast experience with several donor
          implementations, reporting and other compliance requirements including
          for USAID, DFID, EU, ECHO, UNHCR, UNDP, UNOPs, FAO, Danida, IRISH AID,
          SIDA and GCC, among others
        </p>
        <p>
          Possessing advanced expertise in cutting-edge M&E, program design,
          strategic project planning and coordination, in-depth qualitative and
          quantitative research, and forward-thinking business plan development.
          Repeated success leveraging a robust knowledge base to support seismic
          and transformative initiatives with sectoral emphasis in public health
          with focus on HIV/AIDS, TB and Malaria, food and agriculture security,
          education, forestry, climate change, natural resources management,
          governance and peace building, resilience and value chain management.
        </p>
        <p>
          He has over 20 years’ experience in Monitoring & Evaluation of Public
          health programs with a bias towards HIV/AIDS, TB, Malaria, RMNCH and
          OVC. His role and skills include the M&E cycle from design,
          implementation, supervision, quality assurance through data quality
          assessments (DQA), reporting and sharing that cut across HIV/AIDs
          programme from Prevention, care, treatment, VMMC among others. He has
          worked as a HIV/AIDS M&E Specialist of International NGOs including
          CARE Kenya, Family Health International, Danya International, Plan
          International and a USA government Agency, CDC as a M&E Technical
          Advisor for 60 PEPFAR Implementing Partners in Kenya over a 3-year
          period. He also has experience in Site Improvement Management Systems
          (SIMS) with DQA as an integral part of it.
        </p>
        </div>
        </div>
      </div>
      </Zoom>
      <Footer />
    </>
  );
}
export default Profile3;
